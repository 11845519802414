import ct from 'countries-and-timezones'
// import {
//     isSafari,
//     osVersion,
//     isWindows,
//     osName,
//     OsTypes,
//     isEdge,
// } from 'react-device-detect'

export const getDefaultLocale = () => {
    const intl = Intl.DateTimeFormat().resolvedOptions()
    if (!intl.locale) {
        return 'en-US'
    }
    const timezone = ct.getCountryForTimezone(intl?.timeZone)
    if (!timezone?.id) {
        return 'en-US'
    }

    if (intl.locale.search('-') !== -1 || intl.locale.search('_') !== -1) {
        return intl.locale
    } else {
        return intl.locale + '-' + timezone?.id
    }
}
