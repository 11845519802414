import React, {createContext, Dispatch, useContext, useReducer} from "react";
import Actions from './Actions';

export type Global = {
    userParams:any;
}

type GlobalState = Global;

const defaultValue: Global = {
    userParams: {},
}

const GlobalContext = createContext<Global | undefined>(defaultValue);

export function useGlobalState() {
    const state = useContext(GlobalContext);
    if (!state) throw new Error('GlobalProvider not found');
    return state;
}


type Action =
  | { type: string; payload: any };

  type GlobalDispatch = Dispatch<Action>;
const GlobalDispatchContext = createContext<GlobalDispatch | undefined>(
  undefined
);

export function useGlobalDisptch() {
    const state = useContext(GlobalDispatchContext);
    if (!state) throw new Error('GlobalDispatchProvider not found');
    return state;
}


function globalReducer(state: GlobalState, action: Action): GlobalState {
  console.log('globalReducer state=', state, ' action=', action);

    switch (action.type) {
      case Actions.USER_PARAMS:
        return {
            ...state,
            userParams: action.payload
        }
    //   case 'TOGGLE':
    //     return state.map(todo =>
    //       todo.id === action.id ? { ...todo, done: !todo.done } : todo
    //     );
    //   case 'REMOVE':
    //     return state.filter(todo => todo.id !== action.id);
      default:
        throw new Error('Unhandled action');
    }
  }

  export function GlobalProvider({ children }: { children: React.ReactNode }) {
    const [globalStatus, dispatch] = useReducer(globalReducer, defaultValue);
    
    return (
      <GlobalDispatchContext.Provider value={dispatch}>
        <GlobalContext.Provider value={globalStatus}>
          {children}
        </GlobalContext.Provider>
      </GlobalDispatchContext.Provider>
    );
  }
  