import React, { FC, useEffect, useState } from 'react';
import queryString from 'query-string';
import { Button } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';


const EventRefuse: FC = () => {
    const [size] = useState<SizeType>('large');
    const [eventTitle, setEventTitle] = useState('');
    const [inviterFullName, setInviterFullName] = useState('');

    useEffect(() => {
        const parseParams:any = queryString.parse(window.location.search);
        console.log('parseParams=', parseParams);
        setEventTitle(parseParams.eventTitle);
        setInviterFullName(parseParams.inviterFullName);
        
    }, []);

    const clickedComfirmButton = () => {
        window.close();
    }


  return (
    <div className="EventRefuse">
      <h1>이벤트 초대가 거절되었습니다</h1>
      <h3>({inviterFullName})님이 초대한 ({eventTitle}) 이벤트가 '거절' 처리 되었습니다</h3>
      <Button type="primary" shape="round"  size={size} onClick={clickedComfirmButton}>
        확인
      </Button>
    </div>
  )
};

export default EventRefuse;