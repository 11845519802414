import { osName, OsTypes } from 'react-device-detect'
import queryString from 'query-string'
import axios from 'axios'

const launchApp = (params: any = undefined, includeSearch: boolean = true) => {
    let deepLinking: string | undefined = ''
    switch (osName) {
        case OsTypes.MAC_OS:
            deepLinking = process.env.REACT_APP_DEEPLINKG_MACOS
            break
        case OsTypes.IOS:
            deepLinking = process.env.REACT_APP_DEEPLINKG_IOS
            break
        case OsTypes.Android:
            deepLinking = process.env.REACT_APP_DEEPLINKG_ANDROID
            break
        case OsTypes.Windows:
            deepLinking = process.env.REACT_APP_DEEPLINKG_WINDOWS
            break
    }

    let finalurl = deepLinking
    if (includeSearch) {
        finalurl += window.location.search
    }
    if (params) {
        finalurl += '?'
        finalurl += queryString.stringify(params)
    }
    // console.log('finalUrl=', finalurl);
    window.open(finalurl, '_self')
}

const getAppStoreTarget = () => {
    switch (osName) {
        case OsTypes.MAC_OS:
            return '_self'
        case OsTypes.IOS:
            return '_blank'
        case OsTypes.Android:
            return '_blank'
        case OsTypes.Windows:
            return '_self'
        default:
            return '_blank'
    }
}

const gotoStore = (target: string = '_self') => {
    getStoreUrl()
        .then((storeUrl: any) => {
            // console.log('launchApp getStoreUrl=', storeUrl)
            window.open(storeUrl, target)
        })
        .catch((err) => {
            console.error(err)
        })
}

const getStoreUrl = () => {
    return new Promise((resolve, reject) => {
        let tempurl = ''
        switch (osName) {
            case OsTypes.IOS:
                resolve(process.env.REACT_APP_STORE_URL_IOS)
                return
            case OsTypes.Android:
                resolve(process.env.REACT_APP_STORE_URL_ANDROID)
                return

            case OsTypes.MAC_OS:
                tempurl = String(process.env.REACT_APP_STORE_URL_MACOS)
                if (tempurl.length > 0) {
                    resolve(tempurl)
                    return
                }
                break

            case OsTypes.Windows:
                tempurl = String(process.env.REACT_APP_STORE_URL_WINDOWS)
                if (tempurl.length > 0) {
                    resolve(tempurl)
                    return
                }
                break
        }

        let params = {}

        switch (osName) {
            case OsTypes.MAC_OS:
                params = { os: 'ios', store: 'macstore', version: '999.0.0' }
                break
            case OsTypes.Windows:
                params = { os: 'ios', store: 'windowstore', version: '999.0.0' }
                break
        }

        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_VERSION_ENDPOINT,
            url: '/v2/version/endpoint',
            params: params,
            withCredentials: true,
        })
            .then((response: any) => {
                // console.log('getStoreUrl response.data=', response.data)
                if (response.data.error?.code !== 0) {
                    reject(response.data.error.code)
                } else {
                    resolve(response.data.response.packageurl)
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export { launchApp, gotoStore, getStoreUrl, getAppStoreTarget }
