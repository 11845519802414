import React, { FC, useCallback, useEffect, useState } from 'react'
import queryString from 'query-string'
import { getUserLocale } from 'get-user-locale'
import { Grid, Image } from 'antd'
import { launchApp } from '../../util/launchApp'
import BodyKo from './BodyKo'
import BodyEn from './BodyEn'
import { getResponsiveStyle, HomeStyleID } from '../../../style/home/Style'
import { isMacOs, isWindows } from 'react-device-detect'

const { useBreakpoint } = Grid

const LaunchApp: FC = (props: any) => {
    // const os = osName + osVersion;
    // console.log('params=', useParams());
    // console.log('location=', useLocation());

    const screens = useBreakpoint()
    const [logoImageStyle, setLogoImageStyle] = useState(
        getResponsiveStyle(screens, HomeStyleID.moimLogoImage)
    )

    useEffect(() => {
        // console.log('location=', window.location);
        _launchAppWithOsChecking()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLogoImageStyle(
            getResponsiveStyle(screens, HomeStyleID.moimLogoImage)
        )
    }, [screens])

    const _launchAppWithOsChecking = () => {
        if (isPossibleOS()) {
            _launchApp()
        }
    }

    const _launchApp = useCallback(() => {
        if (window.location.hash) {
            const parseHash: any = queryString.parse(window.location.hash)
            // console.log('parseHash=', parseHash);

            if (
                typeof parseHash.access_token !== 'undefined' &&
                typeof parseHash.token_type !== 'undefined'
            ) {
                let stateObject: any
                // 구글 페이지 호출시 인자값
                if (parseHash.state) {
                    stateObject = JSON.parse(parseHash.state)
                    if (stateObject) {
                    }
                }

                if (stateObject.service_type === 'google') {
                    let params: any = {
                        googleId: process.env.REACT_APP_GSI_CLIENT_ID,
                        googleAccessToken: parseHash.access_token,
                        googleTokenType: parseHash.token_type,
                        type: stateObject.type,
                    }

                    // from Google Login
                    launchApp(params)
                } else {
                    console.error('Google not service type')
                }
            } else {
                launchApp()
            }
        } else {
            const parseQeuryString: any = queryString.parse(
                window.location.search
            )
            // console.log('parseQeuryString=', parseQeuryString);
            if (parseQeuryString?.service_type === 'apple') {
                let params: any = {
                    type: parseQeuryString.type,
                    appleIdToken: parseQeuryString.id_token,
                    firstName: parseQeuryString.firstName,
                    lastName: parseQeuryString.lastName,
                }

                launchApp(params, false)
            } else {
                launchApp()
            }
        }
    }, [])

    const isPossibleOS = () => {
        //
        if (isMacOs) {
            return true
        }
        if (isWindows) {
            return true
        }

        return true
    }

    const Body = () => {
        switch (getUserLocale()) {
            case 'ko-KR':
                return (
                    <BodyKo
                        launchApp={_launchAppWithOsChecking}
                        possibleOs={isPossibleOS()}
                    />
                )
            default:
                return (
                    <BodyEn
                        launchApp={_launchAppWithOsChecking}
                        possibleOs={isPossibleOS()}
                    />
                )
        }
    }

    return (
        <div className="LaunchApp">
            <a href="https://moim.in" target="_blank" rel="noopener noreferrer">
                <Image
                    src="https://moim-v2-logo.s3.ap-northeast-2.amazonaws.com/moim2-logo-with-full-text.png"
                    preview={false}
                    alt="moim"
                    style={logoImageStyle}
                >
                    {' '}
                </Image>
            </a>
            <br />
            <br />
            <br />
            <br />
            <Body />
            {/* Current break point:{' '}
            {Object.entries(screens)
                .filter((screen) => !!screen[1])
                .map((screen) => (
                    <Tag color="blue" key={screen[0]}>
                        {screen[0]}
                    </Tag>
                ))} */}
        </div>
    )
}

export default LaunchApp
