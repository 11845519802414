import React, { FC, useEffect, useState } from 'react'
import { Button, Typography, Grid, Col, Row } from 'antd'
import { gotoStore, getAppStoreTarget } from '../../util/launchApp'
import { getResponsiveStyle, HomeStyleID } from '../../../style/home/Style'

const { Title } = Typography
const { useBreakpoint } = Grid

type Props = {
    launchApp: any
    possibleOs: boolean
}

const BodyKo: FC<Props> = ({ launchApp, possibleOs }) => {
    const screens = useBreakpoint()
    const [gotoStoreButtnStyle, setGotoStoreButtonStyle] = useState(
        getResponsiveStyle(screens, HomeStyleID.gotoStoreButton)
    )
    const [explainTitleStyle, setExplainTitleStyle] = useState(
        getResponsiveStyle(screens, HomeStyleID.explainTitle)
    )
    const [launchAppButtnStyle, setLaunchAppButtonStyle] = useState(
        getResponsiveStyle(screens, HomeStyleID.launchAppButton)
    )

    useEffect(() => {
        // console.log('location=', window.location);
    }, [])

    useEffect(() => {
        setGotoStoreButtonStyle(
            getResponsiveStyle(screens, HomeStyleID.gotoStoreButton)
        )
        setExplainTitleStyle(
            getResponsiveStyle(screens, HomeStyleID.explainTitle)
        )
        setLaunchAppButtonStyle(
            getResponsiveStyle(screens, HomeStyleID.launchAppButton)
        )
    }, [screens])

    const _gotoStore = () => {
        gotoStore(getAppStoreTarget())
    }

    return (
        <div>
            <Row justify="center" align="middle">
                <Col span={24}>
                    <Title style={explainTitleStyle} level={2}>
                        Link requires moim app
                    </Title>
                </Col>
            </Row>
            <br />
            {possibleOs && (
                <Button
                    style={gotoStoreButtnStyle}
                    type="primary"
                    value="large"
                    onClick={_gotoStore}
                >
                    Install
                </Button>
            )}
            {!possibleOs && (
                <Button
                    style={gotoStoreButtnStyle}
                    type="primary"
                    value="large"
                >
                    Supported soon
                </Button>
            )}
            <br />
            <br />
            {possibleOs && (
                <Button
                    style={launchAppButtnStyle}
                    value="large"
                    onClick={launchApp}
                >
                    Launch moim app
                </Button>
            )}
        </div>
    )
}

export default BodyKo
