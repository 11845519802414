import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { useScript } from 'usehooks-ts'
import { Layout, notification, Typography } from 'antd'
import { getDefaultLocale } from '../../util/locale/Locale'
const { Header, Footer, Content } = Layout
const { Text, Title } = Typography

const openNotification = (
    message: string,
    description: string,
    duration: number = 10
) => {
    const args = {
        message: message,
        description: description,
        duration: duration,
    }
    notification.open(args)
}

const goToLaunchApp = (
    navigate: any,
    id_token: string,
    user: any,
    code: string
) => {
    if (!id_token) {
        openNotification('Error', 'Apple Sign In Error')
    } else {
        const params = queryString.parse(window.location.search)
        let contextValue
        if (params && params.type) {
            contextValue = params.type
        }
        let url =
            '/?type=' +
            contextValue +
            '&service_type=apple&id_token=' +
            id_token +
            '&code=' +
            code
        if (user?.name) {
            url +=
                '&firstName=' +
                user.name.firstName +
                '&lastName=' +
                user.name.lastName
        }

        navigate(url)
    }
}

const AppleSignIn = () => {
    const backgroundColor = 'white'
    // console.log('getDefaultLocale()=', getDefaultLocale())
    const SCRIPT = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${getDefaultLocale().replace(
        '-',
        '_'
    )}/appleid.auth.js`
    // console.log('SCRIPT=', SCRIPT);
    const status = useScript(SCRIPT)

    const navigate = useNavigate()

    React.useEffect(() => {
        document.addEventListener('AppleIDSignInOnSuccess', (event: any) => {
            // console.log('event.detail=', event?.detail)
            goToLaunchApp(
                navigate,
                event?.detail?.authorization?.id_token,
                event?.detail?.user,
                event?.detail?.authorization?.code
            )
        })

        document.addEventListener('AppleIDSignInOnFailure', (event: any) => {
            openNotification('Error', event?.detail?.error)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const wnd: any = window
        // console.log('status=', status, ' window.AppleId=', wnd.AppleID);

        if (status !== 'ready') {
            return
        }

        const authOptions = {
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: 'email name',
            redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
            responseType: 'code id_token',
            responseMode: 'form_post',
            usePopup: true,
        }

        // console.log('window.AppleId=', wnd.AppleID, ' authOptions=', authOptions);
        wnd.AppleID.auth.init(authOptions)

        wnd.AppleID.auth
            .signIn()
            .then((data: any) => {
                // console.log('data=', data);
            })
            .catch((err: any) => {
                // console.log('err=', err);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    const getTitle = () => {
        switch (getDefaultLocale()) {
            case 'ko-KR':
                return '로그인은 자동 팝업됩니다.'
            default:
                return 'Login starts automatically.'
        }
    }

    const getText = () => {
        switch (getDefaultLocale()) {
            case 'ko-KR':
                return '팝업이 닫혀있다면 로그인 버튼을 눌러주세요.'
            default:
                return 'If the pop-up is closed, please click the login button.'
        }
    }

    return (
        <div>
            <Layout style={{ backgroundColor }}>
                <Header style={{ backgroundColor }}></Header>
                <Header style={{ backgroundColor }}></Header>
                <Header style={{ backgroundColor }}></Header>
                <Content>
                    <div
                        id="appleid-signin"
                        data-color="black"
                        data-border="false"
                        data-type="sign in"
                        style={{
                            height: '40px',
                            width: '210px',
                            cursor: 'pointer',
                            margin: 'auto',
                        }}
                    ></div>

                    <br />
                    <Title level={5}>{getTitle()}</Title>
                    <Text underline>{getText()}</Text>
                </Content>
                <Footer style={{ backgroundColor }}></Footer>
            </Layout>
        </div>
    )
}

export default AppleSignIn
