import React, { FC } from 'react'
import { Layout } from 'antd'
import { LaunchApp } from '../../components/home'
const { Header, Footer, Content } = Layout

const Home: FC = () => {
    const backgroundColor = 'white'
    return (
        <>
            <Layout style={{ backgroundColor }}>
                <Header style={{ backgroundColor }}></Header>
                <Header style={{ backgroundColor }}></Header>
                <Content></Content>
                <Content className="site-layout-background">
                    <LaunchApp></LaunchApp>
                </Content>
                <Footer style={{ backgroundColor }}></Footer>
            </Layout>
        </>
    )
}

export default Home
