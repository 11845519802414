const gotoStoreButtonBase = {
    backgroundColor: '#f87231',
    textAlign: 'center',
    fontWeight: 'bolder',
    color: 'white',
    borderColor: 'white',
    borderRadius: '7px',
    borderWidth: '3px',
}
const explainTitleBase = {
    fontWeight: 'bolder',
    textAlign: 'center',
}
const launchAppButtonBase = {
    backgroundColor: 'white',
    fontWeight: 'bolder',
    color: '#f87231',
    borderColor: '#f87231',
}

const data: any = {
    moimLogoImage: {
        xs: {
            width: '66%',
        },
        sm: {
            width: '66%',
        },
        md: {
            width: '84%',
        },
        lg: {
            width: '100%',
        },
        xl: {
            width: '100%',
        },
        xxl: {
            width: '100%',
        },
    },
    gotoStoreButton: {
        xs: {
            width: '267px',
            height: '55px',
            fontSize: '1.2rem',
            ...gotoStoreButtonBase,
        },
        sm: {
            width: '317px',
            height: '55px',
            fontSize: '1.2rem',
            ...gotoStoreButtonBase,
        },
        md: {
            width: '385px',
            height: '80px',
            fontSize: '1.5rem',
            ...gotoStoreButtonBase,
        },
        lg: {
            width: '490px',
            height: '80px',
            fontSize: '1.5rem',
            ...gotoStoreButtonBase,
        },
        xl: {
            width: '490px',
            height: '80px',
            fontSize: '1.5rem',
            ...gotoStoreButtonBase,
        },
        xxl: {
            width: '490px',
            height: '80px',
            fontSize: '1.5rem',
            ...gotoStoreButtonBase,
        },
    },
    explainTitle: {
        xs: {
            fontSize: '25px',
            ...explainTitleBase,
        },
        sm: {
            fontSize: '31px',
            ...explainTitleBase,
        },
        md: {
            fontSize: '37px',
            ...explainTitleBase,
        },
        lg: {
            fontSize: '48px',
            ...explainTitleBase,
        },
        xl: {
            fontSize: '48px',
            ...explainTitleBase,
        },
        xxl: {
            fontSize: '48px',
            ...explainTitleBase,
        },
    },
    launchAppButton: {
        xs: {
            width: '263px',
            height: '55px',
            fontSize: '1.2rem',
            borderRadius: '4px',
            borderWidth: '2px',
            ...launchAppButtonBase,
        },
        sm: {
            width: '313px',
            height: '55px',
            fontSize: '1.2rem',
            borderRadius: '4px',
            borderWidth: '2px',
            ...launchAppButtonBase,
        },
        md: {
            width: '381px',
            height: '80px',
            fontSize: '1.5rem',
            borderRadius: '7px',
            borderWidth: '3px',
            ...launchAppButtonBase,
        },
        lg: {
            width: '486px',
            height: '80px',
            fontSize: '1.5rem',
            borderRadius: '7px',
            borderWidth: '3px',
            ...launchAppButtonBase,
        },
        xl: {
            width: '486px',
            height: '80px',
            fontSize: '1.5rem',
            borderRadius: '7px',
            borderWidth: '3px',
            ...launchAppButtonBase,
        },
        xxl: {
            width: '486px',
            height: '80px',
            fontSize: '1.5rem',
            borderRadius: '7px',
            borderWidth: '3px',
            ...launchAppButtonBase,
        },
    },
}

const getResponsiveStyle = (screens: any, id: string) => {
    let breakPoint = 'xs'
    if (screens.sm) {
        breakPoint = 'sm'
    }
    if (screens.md) {
        breakPoint = 'md'
    }
    if (screens.lg) {
        breakPoint = 'lg'
    }
    if (screens.xl) {
        breakPoint = 'xl'
    }
    if (screens.xxl) {
        breakPoint = 'xxl'
    }

    return data[id][breakPoint]
}

const HomeStyleID = {
    gotoStoreButton: 'gotoStoreButton',
    explainTitle: 'explainTitle',
    launchAppButton: 'launchAppButton',
    moimLogoImage: 'moimLogoImage',
}

export { getResponsiveStyle, HomeStyleID }
