import React, { FC, useEffect } from 'react';
import queryString from 'query-string';

const GoogleSignIn: FC = () => {
  useEffect( () => {
    const params = queryString.parse(window.location.search);
    let contextValue;
    if(params && params.type) {
      contextValue = params.type;
    } 

    let url:any = process.env.REACT_APP_GOOGLE_LOGIN_BASE_URL;

    url += '?access_type=online';
    url += '&scope=profile email https://www.googleapis.com/auth/userinfo.profile';
    url += '&prompt=select_account';
    url += '&response_type=token';
    url += '&client_id=' + process.env.REACT_APP_GSI_CLIENT_ID;
    url += '&redirect_uri=' + window.location.origin;
    url += '&flowName=GeneralOAuthFlow';
    url += '&state=' + JSON.stringify({type:contextValue, service_type:'google'});

    // console.log('url=', url);

    window.location.replace(url);
  }, [])


  return (
    <>
  </>
  )

}

export default GoogleSignIn;