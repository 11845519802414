import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import { GlobalProvider } from './components/context/GlobalContext'
import AppleSignIn from './pages/apple/AppleSignIn'
import EventRefuse from './pages/event/EventRefuse'
import GoogleSignIn from './pages/google/GoogleSignIn'
import Home from './pages/home/Home'
import {
    ROUTES_APPLE,
    ROUTES_EVENT_REFUSE,
    ROUTES_GOOGLE,
    ROUTES_MAIN,
} from './routes'

const App: FC = () => (
    <div className="App">
        <GlobalProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={ROUTES_MAIN} element={<Home />} />
                    <Route path={ROUTES_GOOGLE} element={<GoogleSignIn />} />
                    <Route path={ROUTES_APPLE} element={<AppleSignIn />} />
                    <Route
                        path={ROUTES_EVENT_REFUSE}
                        element={<EventRefuse />}
                    />
                </Routes>
            </BrowserRouter>
        </GlobalProvider>
    </div>
)

export default App
